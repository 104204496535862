import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import storiesStyles from "../styles/stories.module.css";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";

const Stories = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { type: { eq: "post" }, publish: { eq: "true" } }
          }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                abstract
                featured {
                  childImageSharp {
                    fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              timeToRead
              excerpt
              id
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  const storiesN = data.allMarkdownRemark.edges.length;
  const storiesPerPage = 5;
  const pageMax = Math.ceil(storiesN / storiesPerPage);
  const [page, setPage] = useState(1);
  const [indexLimit, setIndexLimit] = useState([0, 3]);
  useEffect(() => {
    setIndexLimit([(page - 1) * storiesPerPage, page * storiesPerPage - 1]);
  }, [page]);

  const PageItems = () => {
    let Items = [];

    Items.push(
      <Pagination.Prev onClick={() => setPage(page > 1 ? page - 1 : page)} />
    );

    for (let i = 1; i <= pageMax; i++) {
      Items.push(
        <Pagination.Item
          active={i === page ? true : false}
          onClick={() => setPage(i)}
          activeLabel=""
        >
          {i}
        </Pagination.Item>
      );
    }
    Items.push(
      <Pagination.Next
        onClick={() => setPage(page < pageMax ? page + 1 : page)}
      />
    );
    return Items;
  };

  const VisibleStories = () => {
    const Items = data.allMarkdownRemark.edges.reduce((result, edge, index) => {
      if (indexLimit[0] <= index && index <= indexLimit[1]) {
        result.push(
          <li className={storiesStyles.post} key={edge.node.id}>
            <h2>
              <Link
                to={`/stories/${edge.node.fields.slug}/`}
                className={storiesStyles.titleLink}
              >
                {edge.node.frontmatter.title}
              </Link>
            </h2>
            <div className={storiesStyles.meta}>
              <span>
                Posted on {edge.node.frontmatter.date}
                <span> | </span> {edge.node.timeToRead} min read
              </span>
            </div>
            <div>
              {edge.node.frontmatter.featured && (
                <Img
                  className={storiesStyles.featured}
                  fluid={edge.node.frontmatter.featured.childImageSharp.fluid}
                  alt={edge.node.frontmatter.title}
                />
              )}
            </div>
            <Col lg={9} className={storiesStyles.excerptMargin}>
              <p className={storiesStyles.excerpt}>
                {edge.node.frontmatter.abstract}
              </p>
            </Col>
            <div role="button" className={"moreButton"}>
              <Link to={`/stories/${edge.node.fields.slug}/`}>Read More</Link>
            </div>
          </li>
        );
      }
      return result;
    }, []);
    return Items;
  };

  return (
    <Layout>
      <Metadata
        title="Stories"
        description="Hope Labs publishes original interviews, inspirational stories and insightful reports about activities in the social sector."
      />
      <section className={"sectionMain"} role={"main"}>
        <ul className={storiesStyles.posts}>{VisibleStories()}</ul>
        {storiesN <= storiesPerPage ? null : (
          <Pagination className={"pageSelector"}>{PageItems()}</Pagination>
        )}
      </section>
    </Layout>
  );
};

export default Stories;
